import React, { Fragment, useState, useEffect } from "react";
import logo from "../assests/images/MINIMIS.svg";
import "../Components/styles/launcherPage.css";
import iosRewviewimage from "../assests/launcher_page_img/IOS-review-image.png";
import androidMinimisLauncher from "../assests/launcher_page_img/android-minimis-launcher-image.png";

import CarouselBoothstrapComponent from "../Components/LauncherPageComponent/carouselBoothstrapComponent";
import SDK from "../config";
import axios from "axios";
import {
  AddictiveAppCards,
  FooterSection,
  NewportSection,
  TimeLimitCards,
  WelcomeScreenCards,
} from "../Components/LauncherPageComponent/launcherPageComponent";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const LauncherPage = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Facebook Pixel setup
    if (!window.fbq) {
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );

      window.fbq("init", SDK.FB_PIXEL_ID);
    }

    // Track the Lead event of Facebook on open the Page
    if (window.fbq) {
      window.fbq("track", "Lead");
    }

    // TikTok Pixel setup
    (function (w, d, t) {
      w.TiktokAnalyticsObject = t;
      var ttq = (w[t] = w[t] || []);
      ttq.methods = [
        "page",
        "track",
        "identify",
        "instances",
        "debug",
        "on",
        "off",
        "once",
        "ready",
        "alias",
        "group",
        "enableCookie",
        "disableCookie",
        "holdConsent",
        "revokeConsent",
        "grantConsent",
      ];
      ttq.setAndDefer = function (t, e) {
        t[e] = function () {
          t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
        };
      };
      for (var i = 0; i < ttq.methods.length; i++)
        ttq.setAndDefer(ttq, ttq.methods[i]);
      ttq.instance = function (t) {
        for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
          ttq.setAndDefer(e, ttq.methods[n]);
        return e;
      };
      ttq.load = function (e, n) {
        var r = "https://analytics.tiktok.com/i18n/pixel/events.js",
          o = n && n.partner;
        ttq._i = ttq._i || {};
        ttq._i[e] = [];
        ttq._i[e]._u = r;
        ttq._t = ttq._t || {};
        ttq._t[e] = +new Date();
        ttq._o = ttq._o || {};
        ttq._o[e] = n || {};
        n = document.createElement("script");
        n.type = "text/javascript";
        n.async = !0;
        n.src = r + "?sdkid=" + e + "&lib=" + t;
        e = document.getElementsByTagName("script")[0];
        e.parentNode.insertBefore(n, e);
      };

      ttq.load(SDK.TIK_TOK_PIXEL_ID);
      ttq.page();
    })(window, document, "ttq");

    // Track the Lead event of Tiktok on open the Page
    if (window.ttq) {
      window.ttq.track("Pageview");
    }
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const earlyAccessClick = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      toast.error("Please enter an email.");
      return;
    } else if (!emailRegex.test(email)) {
      toast.error("Email not valid");
      return;
    }

    const queryParams = new URLSearchParams({
      email_address: email,
      status: "subscribed",
      AddToCart: "1",
    });

    const queryString = queryParams.toString();
    axios
      .post(`${SDK.BASE_URL}/api/post_email_mailchimp?${queryString}`)
      .then((response) => {
        console.log("Email saved:", response);
      })
      .catch((error) => {
        console.error("Error saving email:", error);
      });

    // Track the AddToCart event on form submission for Facebook and Tiktok
    if (window.fbq) {
      window.fbq("track", "AddToCart");
    }

    if (window.ttq) {
      window.ttq.track("AddToCart");
    }

    navigate(`/reservation?email=${encodeURIComponent(email)}`);
  };

  const handlePlayStoreClick = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=life.minimis.launcher&hl=en",
      "_blank"
    );
  };

  return (
    <Fragment>
      <Toaster />
      <div className="overflow-x-hidden">
        <div className="minimis_launcher">
          <div className="launcher_navbar d-flex justify-content-between">
            <div>
              <img
                src={logo}
                className="navLogo"
                style={{
                  paintOrder: "stroke fill",
                  fill: "black",
                  "-webkit-text-stroke": "12px black",
                  height: "28px",
                  width: "179px",
                }}
                alt="Logo"
              />
            </div>
            {/** 
            <div>
              <img src={menu} style={{ height: "20px", width: "24px" }} alt="Menu" />
            </div>
             */}
          </div>

          <div className="phone_mockup_section">
            <section className="section1">
              <div>
                <p className="p1">Minimal Distractions. Maximal Life.</p>
                <p className="p2">
                  Minimis Launcher brings your phone back to basics. You control
                  your phone. Not the other way around.
                </p>
              </div>
              <div className="mt-5 emailDiv w-100">
                <form onSubmit={earlyAccessClick} className="pt-4 d-flex">
                  <div>
                    <input
                      type="text"
                      placeholder="Your email..."
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </div>
                  <button type="submit">Get early access</button>
                </form>
              </div>
            </section>
            <section className="section2">
              <div>
                <img src={androidMinimisLauncher} alt="Android Mockup" />
              </div>
            </section>
          </div>
        </div>

        <div className="addictiveApp_cards">
          <AddictiveAppCards />
        </div>

        <div className="call_newport_section">
          <NewportSection />
        </div>

        <div className="timeLimit_cards">
          <TimeLimitCards />
        </div>
        <div className="early_access_cards">
          <div>
            <p className="p1">Get Early Access</p>
            <p className="p2">
              Get in early and help shape the launcher you would use!
            </p>
          </div>
          <div className="pt-4 d-flex">
            <div>
              <input
                type="text"
                placeholder="Your email..."
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            <button onClick={earlyAccessClick}>Get early access</button>
          </div>
        </div>
        <div className="welcome_screen_cards">
          <WelcomeScreenCards />
        </div>

        <div className="android-main-container">
          <div className="android-google-container">
            <div className="android-get-google">
              <div className="android-get-google-text">
                <h3>iOS User?</h3>
                <p>
                  We're in beta testing! Early-access through Beta testing is
                  included with Lifetime Subscriptions.
                </p>
                {/* <div className="android-get-google-launched">
                  <p>We've launched already!</p>
                </div> */}
              </div>
              <div
                className="android-get-google-image"
                style={{ cursor: "pointer" }}
              >
                <button>View our iOS page</button>
                {/* <img
                  src={googlePlaystoreImage}
                  onClick={handlePlayStoreClick}
                  alt=""
                /> */}
              </div>
            </div>
            <div className="android-google-mobile">
              <img src={iosRewviewimage} alt="" />
            </div>
          </div>
        </div>
        <div className="android-download-review">
          <p className="android-download-review-heading">
            What our users are saying about us
          </p>
          <CarouselBoothstrapComponent />
        </div>

        <div className="footer_section">
          <FooterSection />
        </div>
      </div>
    </Fragment>
  );
};

export default LauncherPage;
