import SDK from "../../../config";
import axios from "axios";
import toast from "react-hot-toast";

/**
 * Handles the actions to be taken upon successful payment.
 * @param {Object} paymentIntent - The payment intent object from the payment provider.
 * @param {string} methodType - The type of payment method used.
 * @param {string} cardName - The name on the card.
 * @param {string} email - The email address of the customer.
 * @param {string} phone - The phone number of the customer.
 * @param {string} zipcode - The zipcode of the customer.
 */
const handlePaymentSuccess = async (paymentIntent, methodType, cardName, email, phone, zipcode) => {
  const tagsString = "iOS,RESERVED";
  const queryParams = new URLSearchParams({
    email_address: email,
    status: "subscribed",
    tags: tagsString,
    first_name: cardName,
    phone_number: phone,
    AddToCart: "1",
    reserved: "1",
  });

  try {
    // Track purchase event using Facebook Pixel if available
    if (window.fbq) {
      window.fbq("track", "Purchase", {
        value: SDK.FB_PRICE,
        currency: SDK.FB_CURRNECY,
      });
      console.log("Facebook Pixel Purchase event tracked");
    }

    // Track complete payment event using TikTok Pixel if available
    if (window.ttq) {
      window.ttq.track("CompletePayment");
      console.log("TikTok Pixel CompletePayment event tracked");
    }


    // Save the payment intent details to the server
    const paymentIntentResponse = await fetch(`${SDK.BASE_URL}/api/save-payment-intent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        phone_number: phone,
        zipcode,
        amount: paymentIntent.amount,
        paymentIntentId: paymentIntent.id,
        clientSecret: paymentIntent.client_secret,
        created: new Date(paymentIntent.created * 1000),
        currency: paymentIntent.currency,
        status: paymentIntent.status,
        paymentMethod: paymentIntent.payment_method,
        method_type: methodType,
      }),
    });

    // Check if saving payment intent was successful
    if (!paymentIntentResponse.ok) {
      const errorData = await paymentIntentResponse.json();
      console.error("Error saving payment intent:", errorData.error);
      toast.error("Failed to save payment details.");
      return;
    }

    // Send email to Mailchimp
    const mailchimpResponse = await axios.post(`${SDK.BASE_URL}/api/post_email_mailchimp?${queryParams.toString()}`);
    
    // Log Mailchimp response for debugging
    console.log("Mailchimp response:", mailchimpResponse.data);

  } catch (error) {
    console.error("Error processing payment success:", error);
    toast.error("Please try again.");
  }
};

export default handlePaymentSuccess;
