import { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import LauncherPage from "./pages/launcherPage";
import ReservationPage from "./Components/LauncherPageComponent/reservationPage";
import PrivacyPolicies from "./pages/privacyPolicies";
import CheckOutPage from "./Components/LauncherPageComponent/checkOutPage";
import ConfirmationPageComponent from "./Components/LauncherPageComponent/confirmationPageComponent";
import TermsOfServicesPage from "./pages/termsOfServicesPage";
import ScrollToTop from "./Components/scrollToTop/scrollToTop";

const App = () => {
  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
  }, []);
  

  return (
    <div className="dark">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LauncherPage />} />
        <Route path="/reservation" element={<ReservationPage />} />
        <Route path="/launcher-privacy-policy" element={<PrivacyPolicies />} />
        <Route path="/terms-of-service" element={<TermsOfServicesPage />} />
        <Route path="/payment-checkout" element={<CheckOutPage />} />
        <Route path="/confirmation" element={<ConfirmationPageComponent />} />
        <Route path="/*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
};

export default App;
