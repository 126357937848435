import React, { Fragment } from "react";
import logo_light from "../../assests/images/logo_light.svg";
import { Link } from "react-router-dom";
import group1img1 from "../../assests/launcher_page_img/1st group 1.png";
import group1img2 from "../../assests/launcher_page_img/1st group 2.png";
import group1img3 from "../../assests/launcher_page_img/1st group 3.png";
import group1img4 from "../../assests/launcher_page_img/1st group 4.png";
import group2img1 from "../../assests/launcher_page_img/2nd group 1.png";
import group2img2 from "../../assests/launcher_page_img/2nd group 2.png";
import group2img3 from "../../assests/launcher_page_img/2nd group 3.png";
import group2img4 from "../../assests/launcher_page_img/2nd group 4.png";
import group3img1 from "../../assests/launcher_page_img/3rd group 1.png";
import group3img2 from "../../assests/launcher_page_img/3rd group 2.png";
import group3img3 from "../../assests/launcher_page_img/3rd group 3.png";
import group3img4 from "../../assests/launcher_page_img/3rd group 4.png";

const AddictiveAppCards = () => {
  return (
    <Fragment>
      <div className="d-flex">
        <div className="app_cards">
          <img src={group1img1} alt="" />
          <img src={group1img2} alt="" />
          <img src={group1img3} alt="" />
          <img src={group1img4} alt="" />
        </div>
      </div>
      <div className="addictiveApp_text">
        <p className="p1">Get Control Over Addictive Apps</p>
        <p className="p2">
          Smartphone UIs make it too easy to start doom-scrolling. Minimis
          Launcher builds in mindfulness checks, so you can be fully mindful of
          your phone usage.
        </p>
      </div>
    </Fragment>
  );
};

const NewportSection = () => {
  return (
    <Fragment>
      <div className="call_newport_text">
        <div>
          <p className="p1">
            “It’s easy to be seduced by the latest app or service, but then
            forget its cost in terms of the most important resource we
            possess: the minutes of our life.”
          </p>
          <br />
          <p className="p2">- Cal Newport, Digital Minimalism</p>
        </div>
      </div>
    </Fragment>
  );
};

const TimeLimitCards = () => {
  return (
    <Fragment>
      <p className="p1">
        15-minute limits to break big tech’s chokehold on your attention
      </p>
      <div className="d-flex justify-content-center align-items-center">
        <div className="app_cards">
          <img src={group2img1} alt="" />
          <img src={group2img2} alt="" />
          <img src={group2img3} alt="" />
          <img src={group2img4} alt="" />
        </div>
      </div>
    </Fragment>
  );
};

const WelcomeScreenCards = () => {
  return (
    <Fragment>
      <p className="p1">Get started in 3 minutes. Simple, hassle-free setup.</p>
      <div className="d-flex justify-content-center align-items-center">
        <div className="app_cards">
          <img src={group3img1} alt="" />
          <img src={group3img2} alt="" />
          <img src={group3img3} alt="" />
          <img src={group3img4} alt="" />
        </div>
      </div>
    </Fragment>
  );
};

const FooterSection = () => {
  return (
    <Fragment>
      <div className="footer-img">
        <img src={logo_light} alt="" />
      </div>
      <div className="footer_link">
        <Link to="/terms-of-service">Terms and Conditions</Link>
        <Link to="/launcher-privacy-policy">Privacy Policy</Link>
      </div>
    </Fragment>
  );
};

export {
  AddictiveAppCards,
  NewportSection,
  TimeLimitCards,
  WelcomeScreenCards,
  FooterSection,
};
